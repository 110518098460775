/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;700&display=swap");
@import "~ngx-toastr/toastr";
@import url(assets/_color.scss);

* {
  padding: 0;
  margin: 0;
}
html {
  font-size: 10px;
}
*:focus {
  outline: none !important;
}
button {
  cursor: pointer;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4 !important;
}
body {
  font-family: "Open Sans", sans-serif;
  color: #414042;
  overflow-x: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

html[dir="rtl"] {
  body,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Cairo", sans-serif !important;
  }
  select {
    background: url(./assets/images/icon/down-arrow.png) no-repeat right;
    -webkit-appearance: none;
    background-position-x: 5%;
  }
}

.globle-page {
  background: url(./assets/images/slider.png);
  min-height: 350px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.globle-border {
  background: url(./assets/images/bg.png);
  /* background-repeat: no-repeat; */
  background-size: contain;
  background-position: top center;
  padding: 80px 0;
  color: #fff;
  position: relative;
  background-color: #030d26;
  &:before {
    content: "";
    height: 10px;
    width: 100%;
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    background-color: #030d26;
  }
  h4 {
    margin-bottom: 40px;
    font-size: 3.8rem;
    font-weight: bold;
  }
}

.home-service {
  .icon {
    img {
      max-width: 250px;
      margin: 0 auto 50px;
      display: block;
    }
  }

  h3 {
    border-bottom: 5px solid #0e5aa2;
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 15px;
  }
  h3 p {
    font-size: 30px;
    margin-bottom: 0;
  }
  h4 {
    p {
      font-size: 3.8rem;
    }
  }
  p {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1280px !important;
  }
}

.custom-btn {
  border-radius: 45px;
  color: #fff;
  border: none;
  background-color: #0e5aa2;
  height: 6rem;
  font-weight: bold;
  padding: 0 20px;
  min-width: 120px;
  font-size: 15px;
  img {
    display: inline-block;
  }
  &.light {
    background-color: #efefef;
    color: #414042;
  }
}

.form-group {
  .form-control {
    background-color: #efefef;
    border-radius: 50px;
    color: #414042;
    height: 6rem !important;
    font-size: 2rem;
    padding: 0 2.5rem;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-expansion-panel-header {
  height: unset !important;
  padding: 20px 30px !important;
  align-items: flex-start !important;
}

.mat-expansion-panel-header {
  p {
    font-size: 15px;
    color: #414042;
    margin-bottom: 5px;
    b {
      line-height: 1.5;
    }
  }
}

.mat-expansion-panel-content {
  .content-wrapper {
    // background-color: #DCDCDC;
    background-color: #eaeaea;
    padding: 20px;
    border-radius: 12px;
  }
}

@media (max-width: 991px) {
  .globle-page {
    min-height: 270px;
  }
  .logo-footer {
    margin-bottom: 30px;
  }
  .home-service {
    h4 {
      font-size: 3.5rem;
      p {
        font-size: 3.5rem;
      }
    }
  }
}

// .mat-expansion-panel-content .content:last-child .circal:last-child{
//   background-color: #F00;
//   &::after {
//     content: "" !important;
//     position: absolute;
//     left: calc(50% - 35px);
//     transform: translateX(-50%);
//     background: url(assets/images/icon/icon-7.svg);
//     bottom: 105px;
//     width: 45px;
//     height: 45px;
//     background-size: 100%;
//     z-index: 9;
//   }
// }
.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
textarea {
  border-radius: 30px !important;
}
.form-group textarea.form-control {
  height: auto !important;
  resize: none;
  padding: 30px;
}

html[dir="rtl"] {
  .menu ul li a:after {
    right: -2.2rem;
    left: unset !important;
  }

  .menu ul li {
    margin-right: unset !important;
    margin-left: 5rem;
    &:last-child {
      margin-left: unset !important;
    }
  }

  .home-service .icon img,
  .tracking-section img.mt-2 {
    transform: rotateY(180deg) !important;
  }

  @media (max-width: 1199px) {
    .menu ul li {
      margin-right: unset !important;
      margin-left: 4rem !important;
      a:after {
        right: -2rem;
      }
    }
  }

  .mat-form-field + .mat-form-field {
    margin-right: 8px;
    margin-left: unset !important;
  }

  mat-expansion-panel .content-wrapper:after {
    right: calc(50% - 43px);
    left: unset !important;
  }

  mat-expansion-panel
    .content-wrapper
    .content:last-child
    .circal:last-child::before {
    right: -72px;
    left: unset !important;
  }
  .circal:before {
    right: -60px;
    left: unset !important;
  }
  .circal:after {
    right: -55px;
    left: unset !important;
  }

  @media (max-width: 991px) {
    mat-expansion-panel .content-wrapper:after {
      right: 0 !important;
      left: unset !important;
    }
    .circal::before {
      right: -26px;
      left: unset !important;
    }
    .circal::after {
      right: -21px;
      left: unset !important;
    }
    mat-expansion-panel
      .content-wrapper
      .content:last-child
      .circal:last-child::before {
      right: -30px;
      left: unset !important;
    }
    .content:first-child .col-lg-6:first-child:after {
      right: -6px;
      left: unset !important;
    }
    mat-expansion-panel
      .content-wrapper
      .content:last-child
      .col-lg-3:last-child:after {
      right: -6px;
      left: unset !important;
    }
  }
}

app-contact {
  .social-list {
    width: 100%;
    max-width: 400px;
    margin-bottom: 15px;
    li {
      display: inline-block;
      margin: 0px 10px 10px 0;
      img {
        width: 70px;
        height: 70px;
        filter: contrast(0) brightness(5.5);
      }
    }
  }
  .contact-p {
    font-weight: bold !important;
    text-transform: uppercase !important;
    font-size: 2rem !important;
    margin-bottom: 25px !important;
    margin-top: -15px !important;
  }
  .contact-list {
    padding: 0;
    max-width: 450px;
    img {
      width: 2.5rem !important;
      height: 2.5rem !important;
      object-fit: contain;
      filter: contrast(0) invert(27) brightness(6.5);
    }
    p {
      opacity: 1 !important;
      font-size: 1.9rem !important;
    }
    a {
      color: #fff !important;
    }
  }
}

.home-service {
  ul {
    margin-bottom: 60px;
    li {
      margin-bottom: 15px;
    }
  }
}

select {
  background: url(./assets/images/icon/down-arrow.png) no-repeat right;
  -webkit-appearance: none;
  background-position-x: 95%;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: #030d26 !important;
}

/*loader******/
.loader {
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
}
.loader img {
  object-fit: contain;
  animation: opac 0.9s ease-in-out alternate-reverse infinite;
}

@-webkit-keyframes opac {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@keyframes opac {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

app-contact {
  .social-list li img {
    width: 5.5rem !important;
    height: 5.5rem !important;
    margin-inline-end: 10px !important;
    margin-bottom: 2rem !important;
  }
  .social-list {
    max-width: 400px !important;
  }
  ul {
    margin-bottom: 5rem !important;
  }
}
@media print {
  .dontPrint {
    display: none !important;
  }
}

@media (max-width: 1400px) {
  html {
    font-size: 9px;
  }
  .menu ul li a {
    font-size: 1.8rem !important;
    &:after {
      width: 1.2rem !important;
      height: 1.2rem !important;
    }
  }

  app-contact {
    .contact-p {
      font-size: 2.3rem !important;
    }
  }
  app-navbar {
    .logo {
      width: 24rem;
    }
  }
}

@media (max-width: 1200px) {
  html {
    font-size: 8px;
  }
}
@media (max-width: 991px) {
  html {
    font-size: 7px;
  }
  app-navbar {
    .menu ul li {
      margin-right: 4rem !important;
      &:last-child {
        margin-right: 0 !important;
      }
      a {
        font-size: 1.8rem !important;
        &:after {
          width: 1rem !important;
          height: 1rem !important;
          left: -2rem !important;
        }
      }
    }
  }
}

@media (max-width: 776px) {
}

input[type='email']{
      direction: ltr !important;
}
